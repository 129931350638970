import { useState } from 'react'

import { FEATURE } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import OnboardingProgress from 'src/components/Onboarding/OnboardingProgress'
import PageHeader from 'src/components/PageHeader/PageHeader'
import ClientSettingsNavigation from 'src/components/Settings/Client/ClientSettingsNavigation'
import SettingsClientConfigurationCell from 'src/components/SettingsClientConfigurationCell'
import useFeature from 'src/lib/hooks/useFeature'
import { useAuth } from 'src/Providers'

const SettingsClientConfigurationPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  const [continueOnboarding, setContinueOnboarding] = useState(false)

  const { hasRole } = useAuth()

  const isAdminOrOwner = hasRole(['ADMIN', 'OWNER'])

  return (
    <>
      <Metadata
        title="Settings - Client - Configuration"
        description="Settings - Client - Configuration"
      />
      <PageHeader
        title="Client Settings"
        parentDataTestId="client-settings-page"
      />
      <ClientSettingsNavigation />
      <div className="max-w-6xl">
        {onboardingFeatureFlag && isAdminOrOwner && continueOnboarding && (
          <OnboardingProgress />
        )}
        <SettingsClientConfigurationCell
          setContinueOnboarding={setContinueOnboarding}
        />
      </div>
    </>
  )
}

export default SettingsClientConfigurationPage
