import { FC } from 'react'

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../Library/Button/Button'

const OnboardingProgress: FC = () => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="p-6">
      <div className="p-6 pl-8 border border-indigo-500 bg-white shadow-lg rounded-lg">
        <div className="flex gap-4 aflex-wrap items-center justify-between">
          <div className="grow">
            <div className="flex gap-6 items-center">
              <ChatBubbleLeftEllipsisIcon className="h-10 w-10 text-indigo-600" />
              <div>
                <p className="text-lg font-bold">
                  Your Hub setup is not complete!
                </p>
                <p className="text-sm text-gray-500">
                  You&apos;re almost there! Just a few more steps to finish
                  configuring your Hub.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center flex gap-2">
            <Button
              fullWidth={false}
              onClick={() => {
                navigate(routes.onboarding())
                trackEvent('Settings', 'onboarding - continue setup')
              }}
              className="flex-nowrap"
            >
              Continue Setup
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingProgress
